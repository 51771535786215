<script>
export default {
  layout: 'twoColumn',
};
</script>
<template>
  <div class="no-coookies__container">
    <h1>No Cookies</h1>
    <p>
      Please note that by blocking any or all cookies you may not have access to certain features, content or
      personalization. <br />
      For more information see our Cookie Policy.
    </p>

    <p>To enable cookies, follow the instructions for your browser below.</p>

    <b>Facebook App: Open links in External Browser</b>

    <p>
      There is a specific issue with the Facebook in-app browser intermittently making requests to websites without
      cookies that had previously been set. This appears to be a defect in the browser which should be addressed soon.
      The simplest approach to avoid this problem is to continue to use the Facebook app but not use the in-app browser.
      This can be done through the following steps:
    </p>

    <p>
      1. Open the settings menu by clicking the hamburger menu in the top right <br />
      2. Choose “App Settings” from the menu <br />
      3. Turn on the option “Links Open Externally” (This will use the device’s default browser)
    </p>

    <b>Enabling Cookies in Internet Explorer 7, 8 & 9</b>
    <p>
      1. Open the Internet Browser <br />
      2. Click Tools > Internet Options > Privacy > Advanced <br />
      3. Check Override automatic cookie handling <br />
      4. For First-party Cookies and Third-party Cookies click Accept <br />
      5. Click OK and OK
    </p>

    <b>Enabling Cookies in Firefox</b>
    <p>
      1. Open the Firefox browser <br />
      2. Click Tools > Options > Privacy > Use custom settings for history <br />
      3. Check Accept cookies from sites <br />
      4. Check Accept third party cookies <br />
      5. Select Keep until: they expire <br />
      6. Click OK
    </p>

    <b>Enabling Cookies in Google Chrome</b>
    <p>
      1. Open the Google Chrome browser <br />
      2. Click Tools > Options > Privacy Options > Under the Hood > Content Settings <br />
      3. Check Allow local data to be set <br />
      4. Uncheck Block third-party cookies from being set <br />
      5. Uncheck Clear cookies <br />
      6. Close all
    </p>

    <b>Enabling Cookies in Mobile Safari (iPhone, iPad)</b>
    <p>
      1. Go to the Home screen by pressing the Home button or by unlocking your phone/iPad <br />
      2. Select the Settings icon. <br />
      3. Select Safari from the settings menu. <br />
      4. Select ‘accept cookies’ from the safari menu. <br />
      5. Select ‘from visited’ from the accept cookies menu. <br />
      6. Press the home button to return the the iPhone home screen. <br />
      7. Select the Safari icon to return to Safari. <br />
      8. Before the cookie settings change will take effect, Safari must restart. To restart Safari press and hold the
      Home button (for around five seconds) until the iPhone/iPad display goes blank and the home screen appears. <br />
      9. Select the Safari icon to return to Safari.
    </p>
  </div>
</template>

<style lang="scss">
.no-coookies {
  &__container {
    padding: 10px;
  }
}
</style>
